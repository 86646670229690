import { Avatar, Button, InputNumber, Modal, ModalProps } from "antd";
import { IStore } from "interfaces";
import { MouseEventHandler, useState } from "react";
import QRCode from "qrcode";
import { v4 as uuid } from "uuid";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export default function QrModal(props: ModalProps & { store: IStore }) {
  const [number, setNumber] = useState(1);

  async function downloadQrCodes() {
    // Generate QR codes
    const qrCodes = await Promise.all(
      Array(number).fill(0).map(async () => {
        const data = {
          storeID: props.store.id,
          bagID: uuid(),
          storeName: props.store.title,
          storeImage: ""
        };
        const dataURL = await QRCode.toDataURL(JSON.stringify(data), {
          type: "image/png",
          errorCorrectionLevel: "L"
        });

        const idx = dataURL.indexOf("base64,") + "base64,".length;
        return dataURL.substring(idx);
      })
    );

    // Create zip file
    const zip = new JSZip();
    qrCodes.forEach((b64, i) => {
      zip.file(`qr_code_${props.store.id}_${i}.png`, b64, { base64: true });
    });

    // Download zip file
    saveAs(
      await zip.generateAsync({ type: "blob" }),
      `qr_codes_${props.store.id}.zip`
    );
  }

  return <Modal
    title="Generate QR Codes"
    {...props}
    footer={
      <>
        <Button
          type="primary"
          style={{ marginLeft: 9 }}
          onClick={((e) => {
            downloadQrCodes();
            props.onCancel?.(e);
          }) as MouseEventHandler<HTMLButtonElement>}
        >
          Download
        </Button>

        <Button style={{ marginLeft: 9 }} onClick={props.onCancel}>
          Close
        </Button>
      </>
    }
  >
    <span>You are generating QR codes for</span>
    <div style={{ margin: "10px 0" }}>
      <span style={{ display: "inline-flex", alignItems: "center" }}>
        <Avatar shape="square" src={props.store.avatar[0].url} />
        <span style={{ marginLeft: 5 }}>{props.store.title}</span>
      </span>
    </div>

    <label htmlFor="amount" style={{ marginRight: 5 }}>Number of QR codes:</label>
    <InputNumber id="amount" min={1} value={number} onChange={n => setNumber(n ?? 1)}></InputNumber>
  </Modal>;
}
