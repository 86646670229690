import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";

import { Button, Col, Modal, Typography } from "antd";
import { useState } from "react";

import { Input } from "antd";

const { TextArea } = Input;
const { Title } = Typography;

/* PDF component below. */
interface PdfProps {
  deliveryAddress: string;
  customerName: string;
  serviceType: string;
  deliveryNotes: string;
  orderNumber: string;
  storeName: string;
  adminNotes: string;
  unipass?: string;
}

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  deliveredBy: {
    marginLeft: "auto",
    marginRight: 10,
    marginTop: 4,
  },
  image: {
    width: 0.5 * 72,
    height: 0.35 * 72,
    marginHorizontal: "auto",
  },
  imageText: {
    fontSize: 7,
    fontFamily: "Open Sans",
    marginHorizontal: "auto",
  },
  title: {
    paddingLeft: 15,
    fontSize: 13,
    lineHeight: 1.5,
    marginVertical: "auto",
    fontWeight: "bold",
    fontFamily: "Open Sans",
  },
  subtitle: {
    paddingLeft: 15,
    fontSize: 11,
    lineHeight: 1.5,
    marginTop: 3,
    marginBottom: 1,
    fontWeight: "bold",
    fontFamily: "Open Sans",
  },
  heading: {
    paddingLeft: 15,
    textAlign: "left",
    fontSize: 9,
    lineHeight: 1.3,
    marginTop: 3,
    fontWeight: "bold",
    fontFamily: "Open Sans",
  },
  textBody: {
    paddingLeft: 15,
    textAlign: "left",
    fontSize: 11,
    lineHeight: 1.3,
    marginTop: 1,
    marginBottom: 3,
    fontWeight: 400,
    fontFamily: "Open Sans",
  },
  row: {
    borderColor: "black",
    borderWidth: 2,
    margin: 10,
    width: 4 * 72 - 20,
    height: 6 * 72 - 20,
  },
  divider: {
    flexDirection: "column",
  },
  acrow: {
    flexDirection: "row",
  },
  rowItem: {
    flex: 1,
    paddingTop: 5,
    paddingBottom: 8,
    borderBottomColor: "black",
    borderBottomWidth: 1,
  },
});

const Pdf = ({
  deliveryAddress,
  customerName,
  serviceType,
  deliveryNotes,
  orderNumber,
  storeName,
  adminNotes,
  unipass,
}: PdfProps) => {
  // Width and height are measured in "pt", where 1 inch = 72pt
  return (
    <Document>
      <Page size={{ width: 4 * 72, height: 6 * 72 }} style={styles.page}>
        <View style={styles.acrow}>
          <View style={styles.page}>
            <View style={styles.row}>
              <View style={styles.acrow}>
                <View style={styles.rowItem}>
                  <Text style={styles.title}>WeCarryBags Shipping Label</Text>
                </View>
                <View style={styles.rowItem}>
                  <View style={styles.deliveredBy}>
                    <Text style={styles.imageText}>Delivered By</Text>
                    <Image src="/images/pdflogo.png" style={styles.image} />
                    <Text style={styles.imageText}>Postage Paid GB</Text>
                  </View>
                </View>
              </View>

              <View style={styles.acrow}>
                <View style={styles.rowItem}>
                  <View style={styles.divider}>
                    <Text style={styles.heading}>Service type</Text>
                    <View style={styles.textBody}>
                      <Text>Premium Delivery</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.rowItem}>
                  <View style={styles.divider}>
                    <Text style={styles.heading}>Print date</Text>
                    <View style={styles.textBody}>
                      <Text>{new Date().getFullYear()}-{new Date().getMonth().toString().padStart(2, "0")}-{new Date().getDay().toString().padStart(2, "0")}</Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.acrow}>
                <View style={styles.rowItem}>
                  <View style={styles.divider}>
                    <Text style={styles.heading}>Store name</Text>
                    <View style={styles.textBody}>
                      <Text>{storeName}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.rowItem}>
                  <View style={styles.divider}>
                    <Text style={styles.heading}>Customer name</Text>
                    <View style={styles.textBody}>
                      <Text>{customerName}</Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.acrow}>
                <View style={styles.rowItem}>
                  <View style={styles.divider}>
                    <Text style={styles.heading}>Order number</Text>
                    <View style={styles.textBody}>
                      <Text>{orderNumber}</Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.acrow}>
                <View style={styles.rowItem}>
                  <View style={styles.divider}>
                    <Text style={styles.heading}>Address</Text>
                    <View style={styles.textBody}>
                      <Text>{deliveryAddress}</Text>
                    </View>
                  </View>
                </View>
                {unipass && (
                  <View style={styles.rowItem}>
                    <View style={styles.divider}>
                      <Text style={styles.heading}>Unipass number</Text>
                      <View style={styles.textBody}>
                        <Text>{unipass}</Text>
                      </View>
                    </View>
                  </View>
                )}
              </View>

              <View style={styles.acrow}>
                <View style={styles.rowItem}>
                  <View style={styles.divider}>
                    <Text style={styles.heading}>Delivery notes</Text>
                    <View style={styles.textBody}>
                      <Text>{deliveryNotes}</Text>
                    </View>
                  </View>

                  <Text style={styles.heading}>Store notes</Text>
                  <View style={styles.textBody}>
                    <Text>{adminNotes}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

/* React component below */

// Describes function signature
interface CloseProp {
  (): void;
}

interface GeneratePdfProps {
  open: boolean;
  close: CloseProp;
  order: any;
  customer: any;
  storeName: string;
}

const NOTE_LIMIT = 135;

const GeneratePdf = ({
  open,
  close,
  order,
  customer,
  storeName,
}: GeneratePdfProps) => {
  const [text, setTextInternal] = useState("");

  const setText = (text: string) => {
    if (text.length <= NOTE_LIMIT) {
      setTextInternal(text);
    }
  };

  const address = [
    order.deliveryAddressType,
    order.deliveryLineAddress,
    order.deliveryAddressCity,
    order.deliveryAddressPostalCode,
    order.deliveryAddressCountry,
  ].filter(Boolean).join("\n");

  const pdf = (
    <Pdf
      deliveryAddress={address}
      customerName={customer ? customer : ""}
      serviceType={order.serviceType}
      deliveryNotes={order.notes || ""}
      orderNumber={order.orderNumber}
      storeName={storeName}
      adminNotes={text}
      unipass={order.unipass}
    />
  );

  return (
    <Modal
      title="Print Delivery Label"
      open={open}
      onCancel={close}
      width={"25vw"}
      bodyStyle={{ height: "13vh" }}
      footer={
        <>
          <PDFDownloadLink document={pdf} fileName="order.pdf">
            {({}) => <Button>Download</Button>}
          </PDFDownloadLink>

          <Button style={{ marginLeft: 9 }} onClick={close}>
            Close
          </Button>
        </>
      }
    >
      <Col>
        <Title level={5} style={{ fontSize: 13 }}>
          Additional notes
        </Title>
        <Col>
          <TextArea value={text} onChange={(e) => setText(e.target.value)} />
        </Col>
        <p
          style={{
            flexDirection: "row-reverse",
            display: "flex",
            marginTop: 7,
          }}
        >
          {`${text.length}/${NOTE_LIMIT}`}
        </p>
      </Col>
    </Modal>
  );
};

export default GeneratePdf;
