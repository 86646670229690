import React, { useState } from "react";
import { useShow, IResourceComponentsProps } from "@refinedev/core";
import { List } from "@refinedev/antd";
import { Space, Button, Descriptions, Select } from "antd";
import { Loader } from "components";
import { IShopifyStore } from "interfaces";
import axios from "axios";

export const ShopifyStoreShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IShopifyStore>();
  const { data, isLoading, refetch } = queryResult;

  const storeData: any = data?.data ? data?.data : {};
  const store: IShopifyStore = storeData;

  const [status, setStatus] = useState<string | null>(null);
  const [updatingStatus, setUpdatingStatus] = useState(false);

  if (isLoading) {
    return <Loader />;
  }

  const updateStatus = async () => {
    try {
      setUpdatingStatus(true);
      await axios.post(
        "https://backend.wecarrybags.co.uk/api/v1/shopify/set-vendor-status",
        {
          id: Number(store.id),
          status: status,
          production: true, // to do: change production to true later
        }
      );
      await refetch();
      setUpdatingStatus(false);
    } catch (err) {
      setUpdatingStatus(false);
      console.log(err);
    }
  };

  return (
    <List
      title={"Order Details"}
      headerButtons={
        <Space>
          <Button onClick={() => history.back()}>Back</Button>
        </Space>
      }
    >
      <div id="exportSpace">
        <Descriptions title="Store details" bordered column={2}>
          <Descriptions.Item label="Store Name">{store.name}</Descriptions.Item>

          <Descriptions.Item label="Store Balance">
            £{(Math.floor(store.balance) / 100).toFixed(2)}
          </Descriptions.Item>

          <Descriptions.Item label="Store Email">
            <a href={`mailto:${store.email}`}>{store.email}</a>
          </Descriptions.Item>

          <Descriptions.Item label="Store Address">
            {store.address && store.address.map(l => <div key={l}>{l}</div>)}
          </Descriptions.Item>

          <Descriptions.Item label="Store Contact Number">
            {store.phone}
          </Descriptions.Item>

          <Descriptions.Item label="Store Website">
            {store.domain && <a href={`https://${store.domain}`} target="_blank">{store.domain}</a>}
          </Descriptions.Item>

          <Descriptions.Item label="Store Status">
            <Select
              style={{ width: "25%" }}
              value={status || store.status}
              onChange={(status) => setStatus(status)}
              options={[
                { label: "Pending", value: "pending" },
                { label: "Accepted", value: "accepted" },
                { label: "Rejected", value: "rejected" },
              ]}
            />
            <Button
              style={{ marginLeft: 9 }}
              disabled={
                updatingStatus
                  ? true
                  : status === null || status === store.status
              }
              onClick={() => updateStatus()}
            >
              Save status
            </Button>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </List>
  );
};
