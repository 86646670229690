import React, { useState } from "react";
import { useShow, IResourceComponentsProps } from "@refinedev/core";
import { List } from "@refinedev/antd";
import { Space, Timeline, Button, Descriptions, Select } from "antd";
import { Loader, Invoice } from "components";
import { IShopifyOrder } from "interfaces";
import GeneratePdf from "pages/orders/GeneratePdf";
import axios from "axios";

export const ShopifyOrderShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IShopifyOrder>();
  const { data, isLoading, refetch } = queryResult;

  const orderData: any = data?.data ? data?.data : {};
  const order: IShopifyOrder = orderData;

  const [status, setStatus] = useState<string | null>(null);
  const [updatingStatus, setUpdatingStatus] = useState(false);

  const [openGenerate, setOpenGenerate] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);

  if (isLoading) {
    return <Loader />;
  }

  const updateStatus = async () => {
    try {
      setUpdatingStatus(true);
      await axios.post(
        "https://backend.wecarrybags.co.uk/api/v1/shopify/update-order-status",
        {
          id: order.id,
          status: status,
          production: true, // to do: change production to true later
        }
      );
      await refetch();
      setUpdatingStatus(false);
    } catch (err) {
      setUpdatingStatus(false);
      console.log(err);
    }
  };

  return (
    <List
      title={"Order Details"}
      headerButtons={
        <Space>
          <Button onClick={() => history.back()}>Back</Button>
          <Button onClick={() => setOpenGenerate(true)}>Generate PDF</Button>
        </Space>
      }
    >
      <GeneratePdf
        order={order}
        customer={order.customerName}
        storeName={order.storeName}
        open={openGenerate}
        close={() => setOpenGenerate(false)}
      />
      {openPdf && <Invoice order={order} setOpenPdf={setOpenPdf} />}
      <div id="exportSpace">
        <Descriptions title="Order Info" bordered column={2}>
          <Descriptions.Item label="Store Name">
            {order.storeName}
          </Descriptions.Item>
          <Descriptions.Item label="Order Number">
            {order.orderNumber}
          </Descriptions.Item>
          {order && order.deliveryAddressCity && (
            <Descriptions.Item label="Delivery Address">
              {order.customerName}
              <br />
              {order.deliveryLineAddress}
              <br />
              {order.deliveryAddressCity}
              <br />
              {order.deliveryAddressPostalCode}
              <br />
              {order.deliveryAddressCountry}
            </Descriptions.Item>
          )}
          {order.notes && (
            <Descriptions.Item label="Delivery notes">
              {order.notes}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Service Price">
            {`${order.shippingCost} ${order.currency}`}
          </Descriptions.Item>
          <Descriptions.Item label="Order Status">
            <Select
              style={{ width: "25%" }}
              value={status || order.orderStatus}
              onChange={(status) => setStatus(status)}
              options={[
                { label: "Delivered", value: "Delivered" },
                { label: "Out for Delivery", value: "Out for Delivery" },
                { label: "Order Packaged", value: "Order Packaged" },
                { label: "Order Received", value: "Order Received" },
              ]}
            />
            <Button
              style={{ marginLeft: 9 }}
              disabled={
                updatingStatus
                  ? true
                  : status === null || status === order.orderStatus
              }
              onClick={() => updateStatus()}
            >
              Save status
            </Button>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Order Status Info" bordered>
          <Descriptions.Item>
            <Timeline mode={"left"} items={order.orderStatusArray} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    </List>
  );
};
